<template>
  <span>
      <span v-for="item, idx in lines" :key="idx">
        <span>{{item }}</span>
        <br>
      </span>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ""
    },
    spliter: {
      type: String,
      default: ","
    }
  },
  data() {
    return {
      lines: []
    }
  },
  mounted() {
    this.lines = this.value.split(this.spliter)
  }
}
</script>

<style>

</style>