<template>
  <div style="min-height:100vh" class="bg">
    <titleLink title="导游信息" :onClick="onClickBack"></titleLink>
    <div style="padding:0 .2rem 0 .2rem">
      <div class="avatar">
        <van-image
          :src="guider.avatar"
          round
          fit="cover"
          width="150"
          height="150"
          style="box-shadow: 0px 5px 10px 5px rgba(0, 0, 0.7, 0.1);z-index:999;"
        />
      </div>
      <card class="info-card">
        <template #content>
          <van-rate
            v-model="guider.rate_star"
            :size="25"
            color="#ffd21e"
            allow-half
            readonly
            void-icon="star"
            void-color="#eee"
          />
          <van-row style=" color: black" class="info-row">
            <span style="font-weight: 600">{{ guider.name }}</span>
          </van-row>
          <van-row v-for="item, idx in fields" :key="idx" >
            <div v-if="item.custom_slot == null" style="margin-top:.3rem">
              {{item.title}}：{{guider[item.name] == null || guider[item.name] == ''? '暂无': guider[item.name]}}
            </div>
            <div v-else>
              <div v-if="item.custom_slot == 'zheng_hao'" class="custom-slot">
                {{guider.role_name}}证号:
                <span :class="guider.tourist_card == '' || guider.tourist_card == null? '': 'img-link'"
                  @click="onImgClick(guider.tourist_card_image)">
                  {{guider.tourist_card == '' || guider.tourist_card == null? '暂无': guider.tourist_card}}
                </span>
              </div>
               <van-row v-if="item.custom_slot == 'certificate'" class="custom-slot" type="flex">
                 <div style="width:20%"></div>
                <div style="margin-right:5px">
                {{guider.role_name}}资格证号:
                </div>
                <div style="display:block;text-align:left">
                  <span :class="guider.tourist_card != '' && guider.tourist_card!= null? 'img-link': ''"
                    @click="onImgClick(guider.tourist_certificate_image)">
                    <splitString 
                      v-if="guider.tourist_certificate != null && guider.tourist_certificate != ''"
                      :value="guider.tourist_certificate"
                    >
                    </splitString>
                    <span v-else>暂无</span>
                  </span>
                </div>
              </van-row>
              <div v-if="item.custom_slot == 'level' && guider.role_name == '导游'" class="custom-slot">
                导游级别: {{guider[item.name]}}
              </div>
              <div v-if="item.custom_slot == 'mobile'" class="custom-slot">
                联系电话：
                <a
                  :href="'tel:' + guider.mobile"
                  style="
                    color: #426cef;
                    text-decoration: underline;
                  "
                >{{guider.mobile}}</a>
              </div>
              <div v-if="item.custom_slot == 'company'" class="custom-slot">
                所属旅行社：
                <a
                  :href="`/guider/companyInfo/${guider.travel_company_id}`"
                  v-if="guider.travel_company_name != null && guider.travel_company_name != ''"
                  style="
                    color: #426cef;
                    text-decoration: underline;
                  "
                >{{guider.travel_company_name}}</a>
                <span v-else> 暂无 </span>
              </div>
              <div v-if="item.custom_slot == 'region'" class="custom-slot">
                地区：{{guider.province}}/{{guider.city}}/{{guider.district}}
              </div>
            </div>
          </van-row>
        </template>
      </card>
    </div>
    <van-popup v-model="showEmptyPickedImg">
      <van-empty
        image="error"
        description="未完善"
      />
    </van-popup>
  </div>
</template>

<script>
import card from "@/views/components/Card";
import titleLink from "@/views/components/TitleLink"
import {mapGetters} from "vuex"
import {ImagePreview} from "vant"
import splitString from "@/views/components/SplitString"
export default {
  components: {
    card, titleLink, splitString
  },
  data() {
    return {
      showQr: false,
      showEmptyPickedImg: false,
      fields: [
        {
          title: "地区",
          name: "region",
          custom_slot: "region"
        },
        {
          title: "性别",
          name: "gender"
        },
        {
          title: "角色",
          name: "role_name"
        },
        {
          title: "导游证",
          name: "tourist_card",
          custom_slot: "zheng_hao"
        },
        {
          title: "导游资格证",
          name: "tourist_certificate",
          custom_slot: "certificate"
        },
        {
          title: "导游级别",
          name: "level",
          custom_slot: "level"
        },
        {
          title: "所属旅行社",
          name: "travel_company_name",
          custom_slot: "company"
        },
        {
          title: "语种",
          name: "language"
        },
        {
          title: "联系电话",
          name: "mobile",
          custom_slot: "mobile"
        },
      ]
    };
  },
  methods: {
    sign_out() {
      this.$router.push("/login");
    },
    onClickBack() {
      this.$router.go(-1)
    },
    onImgClick(url) {
      if(url == '') {
        this.showEmptyPickedImg = true
      } else {
        ImagePreview(url.split(','))
      }
    }
  },
  computed: {
    ...mapGetters('guider', {guider: 'getCurrentGuider'})
  },
  created() {
    this.$store.dispatch('guider/getGuiderById', this.$route.params.id)
  },
};
</script>

<style scoped>
.info-card {
  margin-top: -80px;
  padding-top: 90px;
  font-size: 14px;
  color: grey;
  text-align: center;
  height: 50vh;
  padding-bottom: 2rem;
  overflow: hidden;
  overflow-y: scroll;
}
.info-row {
  margin-top: 15px;
}
.avatar {
  margin-top:80px;
  text-align: center;
  width: 100%;
  display: block;
}
.bg {
  background-image: url('../../assets/images/bg.jpg');
  background-size: 100% 100%;
}
.img-link {
 color: #436def; 
 text-decoration: underline;
}
.custom-slot {
  margin-top: .3rem;
}
</style>